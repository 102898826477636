<template>
  <label class="switch">
    <input
      v-model="inputValue"
      type="checkbox"
      @input="updateValue"
    />
    <span class="slider round">
      <span
        class="slider-option option-1"
        :class="{ active: !inputValue }"
      >
        <slot name="option1"></slot>
      </span>
      <span
        class="slider-option option-2"
        :class="{ active: inputValue }"
      >
        <slot name="option2"></slot>
      </span>
    </span>
  </label>
</template>

<script>
import { ref, watch } from 'vue';
export default {
  props: {
    checkedInput: Boolean,
  },
  setup(props, context) {
    const inputValue = ref(props.checkedInput);

    watch(
      () => props.checkedInput,
      () => {
        inputValue.value = props.checkedInput;
      }
    );

    const updateValue = (event) => {
      console.log('EMIT', event.target.value, typeof event.target.value);
      if (typeof event.target.value === 'string') {
        context.emit('changeValue', !inputValue.value);
      } else {
        context.emit('changeValue', event.target.value);
      }
      // context.emit('changeValue', !checkedInput.value);
      // context.emit('update:checkedInput', event.target.value == true);
    };

    return { updateValue, inputValue };
  },
};
</script>

<style lang="scss" scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 76px;
  height: 32px;
  border-radius: 34px;
  box-shadow: 0px 3px 5px 0px #9299b805;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 24px;
  width: 34px;
  left: 4px;
  bottom: 4px;
  background-color: #2196f3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #fff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #fff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(34px);
  -ms-transform: translateX(34px);
  transform: translateX(34px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 16px;
}

.slider-option {
  color: #5a5f7d;
  &.active {
    color: #fff;
  }
}

.option-1 {
  position: absolute;
  top: 7px;
  left: 14px;
}

.option-2 {
  position: absolute;
  top: 7px;
  right: 14px;
}
</style>
