<template>
  <div
    class="sentiment-circle-xs"
    :class="sentimentClass"
    @click.stop=""
  >
    <FeatherIcon
      :type="sentimentIcon"
      :size="10"
    />
  </div>
</template>

<script>
import { toRefs, computed } from 'vue';

export default {
  props: {
    sentiment: String,
  },
  setup(props) {
    const { sentiment: sentimentType } = toRefs(props);

    const sentimentIcon = computed(() => {
      if (sentimentType.value) {
        if (sentimentType.value === 'positive') return 'smile';
        if (sentimentType.value === 'negative') return 'frown';
        return 'meh';
      }
      return '';
    });
    const sentimentClass = computed(() => {
      let result = '';
      if (sentimentType.value) {
        if (sentimentType.value === 'positive') result = 'positive-bg';
        else if (sentimentType.value === 'negative') result = 'negative-bg';
        else result = 'neutral-bg';
      }
      return result;
    });
    return {
      sentimentIcon,
      sentimentClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.sentiment-circle-xs {
  height: 16px;
  width: 16px;
  line-height: 16px;
  border-radius: 48px;
  padding: 0 3px;
  &.positive-bg {
    background-color: rgba(32, 201, 151, 0.15);
    color: #20c997;
  }
  &.neutral-bg {
    background-color: #fff5e2;
    color: #febc3c;
  }
  &.negative-bg {
    background-color: rgba(255, 77, 79, 0.15);
    color: #ff4d4f;
  }
}
</style>
