<template>
  <div class="page-content">
    <CustomPageHeader
      :hasBottomSubtitle="true"
      :maxWidth="true"
      :has-advance-search="true"
      :has-filter-preset="true"
    >
      <template #title>Message Location</template>
    </CustomPageHeader>

    <div
      class="location-page"
      :class="{ 'is-small-screen': isSmallScreen }"
    >
      <div
        v-if="showMessageList"
        v-show="isShow"
        class="location-left-block"
      >
        <div
          id="loc-auto"
          class="search-wrapper"
        >
          <a-auto-complete
            v-model:value="locationInputValue"
            class="loc-search-input"
            :allow-clear="true"
            style="width: 100%"
            placeholder="Search province or district"
            @change="onLocationChange"
            @select="onLocationSelect"
            @search="onLocationSearch"
          >
            <template #dataSource>
              <a-select-option
                v-for="loc in filteredLocationList"
                :key="loc"
              >
                {{ loc }}
              </a-select-option>
            </template>
          </a-auto-complete>

          <div class="d-flex align-center-v">
            <div
              v-if="isSmallScreen"
              class="mobile-mode-selector mr-2"
            >
              <SwitchToggleButton
                :checkedInput="mobileMode"
                @changeValue="onMobileModeChange"
              >
                <template #option1>
                  <FeatherIcon
                    type="map"
                    size="14"
                  />
                </template>
                <template #option2>
                  <FeatherIcon
                    type="list"
                    size="14"
                  />
                </template>
              </SwitchToggleButton>
            </div>

            <SortByButton
              :hideTitle="true"
              @change="onSortChange"
              ><a-button>
                <img
                  :src="require('@/assets/images/icon/icon-arrow-up-down.svg')"
                /> </a-button
            ></SortByButton>
          </div>
        </div>

        <div class="d-flex stat-block">
          <div class="inner-block">
            <div class="left-side">
              <div class="title-text">Total</div>
              <div class="value-text">
                {{ numeral(sentimentCountStat.value) || 'N/A' }}
              </div>
              <div class="unit-text">Mentioned</div>
            </div>
            <div class="right-side">
              <div class="title-text">Sentiment</div>
              <div class="sentiment-row">
                <div>
                  <div class="flex-align-center">
                    <XSSentiment
                      class="mr-1"
                      :sentiment="'positive'"
                    />
                    {{
                      ((sentimentCountStat.positivePercent || 0) * 100).toFixed(
                        0
                      )
                    }}%
                  </div>
                  <div class="unit-text">Positive</div>
                </div>
                <div>
                  <div class="flex-align-center">
                    <XSSentiment
                      class="mr-1"
                      :sentiment="'neutral'"
                    />
                    {{
                      ((sentimentCountStat.neutralPercent || 0) * 100).toFixed(
                        0
                      )
                    }}%
                  </div>
                  <div class="unit-text">Neutral</div>
                </div>
                <div>
                  <div class="flex-align-center">
                    <XSSentiment
                      class="mr-1"
                      :sentiment="'negative'"
                    />
                    {{
                      ((sentimentCountStat.negativePercent || 0) * 100).toFixed(
                        0
                      )
                    }}%
                  </div>
                  <div class="unit-text">Negative</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="message-list-wrapper">
          <div class="subtitle">
            <span class="subtitle-text">Message List</span>
            <span class="subtitle-value"
              >({{ listResult.totalText }} results)</span
            >
          </div>
          <div
            class="message-list"
            @scroll="onScroll"
          >
            <a-card class="message-card">
              <div class="list-wrapper">
                <div
                  v-if="loading"
                  class="loading-wrapper pa-4"
                >
                  <a-skeleton></a-skeleton>
                </div>
                <template v-if="!loading">
                  <MessageItem
                    v-for="item in currentList"
                    :key="item.info.id"
                    class="list-item"
                    :item="item"
                    :show-category="true"
                    :has-thumb="false"
                    :parentName="'location'"
                    @click="drilldown(item)"
                  />
                  <div
                    v-if="listResult.data && listResult.data.length === 0"
                    class="pt-4 text-center"
                  >
                    No messages
                  </div>
                </template>
                <div
                  v-show="!loading && (hasMore || hasMoreInner)"
                  class="eof"
                >
                  <a-spin />
                </div>
              </div>
            </a-card>
          </div>
        </div>
      </div>

      <div class="search-mobile-wrapper">
        <div
          v-if="isSmallScreen && showMap"
          class="location-floating-nav"
        >
          <!-- Smallscreen top nav -->
          <div class="search-bar">
            <div
              id="loc-auto-mobile"
              class="mobile-search-wrapper"
            >
              <a-auto-complete
                v-model:value="locationInputValue"
                class="loc-search-input"
                :allow-clear="true"
                style="width: 100%"
                placeholder="Search province or district"
                @change="onLocationChange"
                @select="onLocationSelect"
                @search="onLocationSearch"
              >
                <template #dataSource>
                  <a-select-option
                    v-for="loc in filteredLocationList"
                    :key="loc"
                  >
                    {{ loc }}
                  </a-select-option>
                </template>
              </a-auto-complete>
            </div>
          </div>
          <div class="right-tool">
            <div class="mobile-mode-selector">
              <SwitchToggleButton
                :checkedInput="mobileMode"
                @changeValue="onMobileModeChange"
              >
                <template #option1>
                  <FeatherIcon
                    type="map"
                    size="14"
                  />
                </template>
                <template #option2>
                  <FeatherIcon
                    type="list"
                    size="14"
                  />
                </template>
              </SwitchToggleButton>
            </div>
            <div class="mobile-filter-toggle">
              <SortByButton
                class="location-filter-button"
                :hideTitle="true"
                @change="onSortChange"
                ><a-button>
                  <img
                    :src="
                      require('@/assets/images/icon/icon-arrow-up-down.svg')
                    "
                  /> </a-button
              ></SortByButton>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="showMap"
        class="location-right-block"
      >
        <div
          v-if="!isSmallScreen"
          class="toggle-button"
          :class="{ open: isShow }"
          @click="toggleShow"
        >
          <FeatherIcon
            v-if="isShow"
            type="chevron-left"
            size="14"
            color="#3371ff"
          />
          <FeatherIcon
            v-else
            type="chevron-right"
            size="14"
            color="#3371ff"
          />
        </div>
        <GoogleMap
          :use-search="useSearch"
          :search-obj="searchObj"
          @boundChange="onBoundChange"
        ></GoogleMap>
      </div>

      <!-- <SeeMoreDialog /> -->
    </div>
  </div>
</template>

<script>
import {
  computed,
  reactive,
  provide,
  ref,
  onUnmounted,
  onMounted,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { notification } from 'ant-design-vue';
import _ from 'lodash';

import api from '@/services/api';
import helper from '@/services/helper';

import SortByButton from '@/components/Button/SortByButton.vue';
// import FilterButton from '@/components/Button/FilterButton.vue';
// import DateRangePickerButton from '@/components/Button/DateRangePickerButton.vue';
import MessageItem from '@/components/Message/MessageItem.vue';
import GoogleMap from './GoogleMap.vue';
import XSSentiment from './ExtraSmallSentimentIcon.vue';
// import SeeMoreDialog from '@/components/Seemore/SeeMoreDialog.vue';
import SwitchToggleButton from '@/components/Button/SwitchToggleButton.vue';
// import DateRangeButton from '@/components/Button/DateRangeButton.vue';
import CustomPageHeader from '@/components/Layout/CustomPageHeader.vue';

const defaultBound = {
  latitudeMax: 14.053837709692495,
  latitudeMin: 13.452243780551724,
  longitudeMax: 100.8553398637848,
  longitudeMin: 100.14472613621523,
};

export default {
  name: 'Location',
  components: {
    CustomPageHeader,
    SortByButton,
    // FilterButton,
    // DateRangePickerButton,
    MessageItem,
    GoogleMap,
    XSSentiment,
    // SeeMoreDialog,
    SwitchToggleButton,
    // DateRangeButton,
  },
  setup() {
    const isSmallScreen = computed(
      () => store.getters['isSmallerMobileScreen']
    );

    // false = showMap
    const mobileMode = ref(false);
    const onMobileModeChange = (e) => {
      console.log('MODE CHANGE', e, typeof e);
      mobileMode.value = e;
    };
    const showMap = computed(() => {
      if (isSmallScreen.value) {
        console.log(typeof mobileMode.value, mobileMode.value);
        return !mobileMode.value;
      }
      return true;
    });
    const showMessageList = computed(() => {
      if (isSmallScreen.value) {
        return mobileMode.value;
      }
      return true;
    });

    const loadedLocation = ref(false);
    const loading = ref(true);
    const isShow = ref(true);
    const hasMore = ref(true);
    const isShowFilter = ref(false);

    const toggleShow = () => {
      isShow.value = !isShow.value;
    };

    const currentBoundList = reactive({
      ...defaultBound,
    });

    const store = useStore();
    const filterCriteria = computed(
      () => store.getters['filter/filterCriteria']
    );
    const currentPage = ref(1);
    const overrideCriteria = reactive({
      location: {
        hasLocationOnly: true,
        ...currentBoundList,
      },
      paging: {
        // recordPerPage: 1000,
        recordPerPage: 20,
        page: 1,
      },
      sort: {
        field: 'search_score',
        direction: 'desc',
      },
      highlight: {
        enable: true,
      },
    });
    const subCategoryName = ref({});
    const listResult = reactive({
      count: 0,
      total: 0,
      data: [],
      totalPage: 0,
      totalText: 'loading',
    });

    const previousSelect = ref('');

    store.dispatch('config/getLocationData').then(() => {
      loadedLocation.value = true;
      setTimeout(() => {
        filteredLocationList.value = locationList.value.slice(0, 100);
      }, 100);
    });
    const locationList = computed(
      () => store.getters['config/locationNameList']
    );
    const locationNameMap = computed(
      () => store.getters['config/locationNameMap']
    );

    const drilldown = (item) => {
      store.dispatch('seemore/show', item);
    };

    const sentimentCountStat = reactive({});
    const sentimentEngageStat = reactive({});
    const getSentimentStat = async (finalCriteria) => {
      const result = await api.getSentimentStat(finalCriteria).catch(() => {
        notification.error({
          message: 'Error',
          description: 'Cannot get sentiment stat data.',
        });
      });
      if (result && result.message) {
        const { count, engagement } = result.message;
        Object.assign(sentimentCountStat, count);
        Object.assign(sentimentEngageStat, engagement);
      }
    };

    // For next page
    let interOb = null;

    const getMessageList = async (finalCriteria, locArg, options) => {
      currentPage.value = 1;
      loading.value = true;
      const result = await api.getMapDrilldown(finalCriteria).catch(() => {
        notification.error({
          message: 'Error',
          description: 'Cannot get map detail data.',
        });
      });
      if (result && result.message && result.message.data) {
        const highlightResult = await store.dispatch(
          'config/highlightKeywords',
          {
            messageList: result.message.data,
            truncateAt: 0,
          }
        );
        listResult.data = highlightResult;
        subCategoryName.value = result.message.subCategoryName;
        listResult.total = result.message.totalRecord;
        if (result.message.totalRecord > 1000) {
          listResult.count = 1000;
        } else {
          listResult.count = result.message.totalRecord;
        }
        listResult.totalText = helper.numeral(listResult.total);
        listResult.totalPage = result.message.totalPage;
        if (listResult.totalPage > 1) {
          hasMore.value = true;
        } else {
          hasMore.value = false;
        }
        if (!interOb) {
          interOb = new IntersectionObserver(
            (entries) => {
              if (!entries[0].isIntersecting) {
                // Do nothing on leave
              } else {
                // On freakin enter
                onNextPage();
              }
              // On change
            },
            {
              threshold: 1.0,
              root: document.getElementById('app'),
              rootMargin: '0px',
            }
          );
          setTimeout(() => {
            const elem = document.querySelector('.eof');
            if (elem) {
              interOb.observe(elem);
            }
          });
        }
        if (locArg) {
          searchObj.value = {
            ...locArg,
            options,
            resultData: listResult.data,
          };
          useSearch.value = true;
        } else {
          searchObj.value = {
            options,
            resultData: listResult.data,
          };
          useSearch.value = false;
        }
      }
      loading.value = false;
    };

    // current page - inner pagination
    const innerSize = 10;
    const innerPage = ref(1);
    const currentList = computed(() => {
      if (listResult.data) {
        return listResult.data.slice(0, innerPage.value * innerSize);
      } else {
        return [];
      }
    });

    const hasMoreInner = computed(() => {
      const currentShowSize = innerPage.value * innerSize;
      if (currentShowSize < listResult.data.length) {
        return true;
      }
      return false;
    });

    const onNextPage = async () => {
      // Check if inner pagination is over
      if (hasMoreInner.value) {
        innerPage.value = innerPage.value + 1;
      } else {
        let oldData = listResult.data;
        if (currentPage.value < listResult.totalPage) {
          overrideCriteria.paging.page += 1;
          const finalCriteria = {
            ...filterCriteria.value,
            ...overrideCriteria,
          };
          const result = await api.getMapDrilldown(finalCriteria).catch(() => {
            notification.error({
              message: 'Error',
              description: 'Cannot get map detail data.',
            });
          });
          if (result && result.message && result.message.data) {
            // Do append data
            listResult.data = oldData.concat(result.message.data);
          }
        } else {
          // End
          hasMore.value = false;
        }
      }
    };

    const init = async (locArg, options) => {
      // console.log('Bound init msg list', locArg);
      // TODO --- check for search input and set overrideCriteria
      // if (boundArg) {
      //   overrideCriteria.location = {
      //     hasLocationOnly: true,
      //     latitudeMax: boundArg.latitudeMax,
      //     latitudeMin: boundArg.latitudeMin,
      //     longitudeMax: boundArg.longitudeMax,
      //     longitudeMin: boundArg.longitudeMin,
      //   };
      // }

      const finalCriteria = {
        ...filterCriteria.value,
        ...overrideCriteria,
      };
      getSentimentStat(finalCriteria);
      getMessageList(finalCriteria, locArg, options);
    };

    // Search
    const useSearch = ref(false);
    const searchObj = ref({});
    const locationInputValue = ref('');
    const filteredLocationList = ref([]);
    const onLocationSearch = _.debounce(() => {
      if (locationInputValue.value && locationList.value) {
        const locName = locationInputValue.value.trim();
        const locList = locationList.value;
        // TODO SPLIT search mode
        // if (locName.includes(' ') || locName.includes('+')) {
        //   // Split mode
        //   const splitted = locName.split(/[\s,+]+/);
        //   console.log(splitted);

        //   filteredLocationList.value = locList.filter((x) => {
        //     let found = false;
        //     for (let i of splitted) {
        //       if (x.indexOf(i) >= 0) {
        //         found = true;
        //         break;
        //       }
        //     }
        //     return found;
        //   });
        //   console.log(filteredLocationList.value);
        // } else
        if (locName) {
          filteredLocationList.value = locList.filter(
            (x) => x.indexOf(locName) >= 0
          );
        } else {
          filteredLocationList.value = locList.slice(0, 100);
        }

        // console.log(
        //   'CALL',
        //   data,
        //   locationInputValue.value,
        //   locationList.value.filter((x) => x.indexOf(locName) >= 0)
        // );
      } else if (locationList.value) {
        const locList = locationList.value;
        filteredLocationList.value = locList.slice(0, 100);
      } else {
        filteredLocationList.value = [];
      }
    }, 300);
    const onLocationChange = (data) => {
      if (!data && previousSelect.value) {
        previousSelect.value = '';
        console.log('Clear !!');
        useSearch.value = false;
        overrideCriteria.location = {
          hasLocationOnly: true,
          ...currentBoundList,
        };
        overrideCriteria.paging = {
          recordPerPage: 200,
          page: 1,
        };
        init(false, {
          isReset: true,
        });
      }
    };
    const onLocationSelect = async (locName) => {
      console.log('onSelect', locName);
      if (locationNameMap.value && locationNameMap.value[locName]) {
        previousSelect.value = locName;
        const locObj = locationNameMap.value[locName];
        // get boundary
        const result = await api.getLocationBoundaryNew(locObj.id);
        if (result.message) {
          const locParam = {
            hasLocationOnly: true,
            amphoe: locObj.amphoe,
            province: locObj.province,
          };
          overrideCriteria.location = locParam;
          overrideCriteria.paging = {
            recordPerPage: 200,
            page: 1,
          };
          init(
            {
              location: locParam,
              boundList: result.message.bound,
              centroidX: result.message.centroidX,
              centroidY: result.message.centroidY,
            },
            {
              isReset: true,
            }
          );
        }
      }
    };

    const onSortChange = (obj) => {
      overrideCriteria.sort = {
        direction: obj.direction,
        field: obj.type,
      };
      init(false, {
        isReset: true,
      });
    };

    const onBoundChange = (obj) => {
      // console.log('ON BOUND CHANGE', obj);
      Object.assign(currentBoundList, obj);
      overrideCriteria.location = {
        hasLocationOnly: true,
        ...obj,
      };
      // console.log(useSearch.value, obj);

      if (useSearch.value) {
        // should do nothing when select location
      } else {
        // search by current bound
        init(false, {
          isUpdate: true,
        });
      }
    };

    watch(
      () => filterCriteria.value,
      () =>
        init(
          {},
          {
            isReset: true,
          }
        )
    );

    onMounted(async () => {
      await store.dispatch('config/getAccountKeyword');
      init(false, {
        isReset: true,
      });
    });

    onUnmounted(() => {
      if (interOb) {
        interOb.disconnect();
      }
      store.dispatch('seemore/close');
    });

    provide('onEditMessage', (e) => {
      helper.saveLocalMessage(e, listResult.data);
    });

    provide('subCategoryName', subCategoryName);

    // hide popover when scrolling
    const onScroll = () => {
      const scrollElement = document.getElementsByClassName('ant-popover');

      if (scrollElement) {
        for (let ele of scrollElement) {
          ele.style.display = 'none';
        }
      }
    };

    const setFilterDate = (timeObj) => {
      store.dispatch('filter/updateTempCriteria', {
        time: timeObj,
      });
      store.dispatch('filter/applyFilter');
    };

    return {
      isSmallScreen,
      mobileMode,
      onMobileModeChange,
      showMessageList,
      showMap,
      loading,
      isShowFilter,
      isShow,
      toggleShow,
      hasMore,
      hasMoreInner,
      init,
      onSortChange,
      listResult,
      currentPage,
      currentList,
      onNextPage,
      drilldown,
      // sentiment
      sentimentCountStat,
      sentimentEngageStat,
      // Search data
      useSearch,
      searchObj,
      locationInputValue,
      locationList,
      filteredLocationList,
      onLocationSearch,
      onLocationChange,
      onLocationSelect,
      onBoundChange,
      numeral: helper.numeral,
      onScroll,
      setFilterDate,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../../config/theme/colors.json';
.page-content {
  height: 100%;
}
.location-page {
  display: flex;
  height: 100%;
  max-height: -webkit-fill-available;

  .location-filter-button {
    height: 36px;
    width: 36px;
    &:hover,
    &:focus {
      color: #5a5f7d !important;
    }
    &.active {
      color: #40a9ff !important;
    }
  }
  .location-left-block {
    flex: 1 1 auto;
    max-width: 30vw;
    min-width: 450px;
    padding: 30px;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    .title-text {
      color: #272b41;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
    }
    .search-wrapper {
      flex-direction: row;
      display: flex;
      column-gap: 8px;
      margin-bottom: 16px;
      .loc-search-input {
        border-radius: 40px;
        overflow: hidden;
        background: #ffffff;
        border: 1px solid $gray-100;
      }
    }
    .stat-block {
      margin-top: 16px;
      margin-bottom: 24px;
      column-gap: 14px;
      width: 100%;
      .inner-block {
        display: flex;
        border-radius: 10px;
        background-color: #fff;
        padding: 10px 0;
        width: 100%;
        .title-text {
          font-weight: 500px;
          font-size: 14px;
          line-height: 18px;
          color: #5a5f7d;
        }
        .unit-text {
          color: #9299b8;
          font-size: 12px;
        }
        .left-side {
          flex: 1 1 auto;
          max-width: 140px;
          border-right: 1px solid #f1f2f6;
          padding: 10px 20px 10px 34px;
          .value-text {
            color: #272b41;
            font-size: 30px;
            font-weight: 500px;
          }
        }
        .right-side {
          flex: 1 1 auto;
          padding: 10px 20px 10px 34px;
          .sentiment-row {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 500;
            color: #5a5f7d;
            margin-top: 16px;
            .unit-text {
              margin-top: 7px;
              font-weight: 400;
            }
          }
        }
      }
    }
    .message-list-wrapper {
      flex: 1 1 auto;
      height: calc(100% - 170px);
      overflow: hidden;
      .subtitle {
        margin-bottom: 18px;
        .subtitle-text {
          color: #272b41;
          font-weight: 500;
          font-size: 16px;
          margin-right: 14px;
        }
        .subtitle-value {
          color: #5a5f7d;
          font-size: 14px;
        }
      }
    }
    .message-list {
      overflow-y: auto;
      height: calc(100% - 220px);
      // overflow: hidden;
      // max-height: calc(100% - 220px);
      flex: 1 1 auto;
      border-radius: 10px;
    }
    .list-wrapper {
      margin: 0;
      padding: 0;
      min-height: 200px;
      .list-item {
        padding-top: 25px;
      }
      .message-item {
        .content-wrapper {
          word-break: break-word;
        }
      }
    }
  }
  .location-right-block {
    flex: 1 1 auto;
    position: relative;
    .toggle-button {
      position: absolute;
      top: 50%;
      left: -2px;
      z-index: 1;
      padding: 7px;
      width: 32px;
      height: 32px;
      text-align: center;
      background-color: #cfe4fd;
      box-shadow: 0px 0px 10px 1px #3371ff5e;
      border-radius: 100px;
      cursor: pointer;
      &.open {
        left: -15px;
      }
    }
  }
  .eof {
    text-align: center;
    padding: 20px;
  }

  .date-range-button .date-range-btn-container .ant-calendar-picker {
    max-width: 100%;
    @media (max-width: 420px) {
      width: 100px !important;
    }
    @media (min-width: 992px) and (max-width: 1600px) {
      width: 160px !important;
    }
    @media (min-width: 1601px) {
      width: 250px !important;
    }

    .ant-calendar-picker-input {
      box-shadow: none;
    }
  }
}
.location-filter-bar {
  display: flex;
  align-items: center;
  column-gap: 8px;
  .ant-btn {
    height: 34px;
  }
  .custom-drp {
    border: none;
    height: 34px;
  }
}

.location-floating-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // position: absolute;
  // top: 0;
  width: calc(100% - 76px);
  z-index: 1;
  padding: 16px 20px;
  // right: 0;
  @media (max-width: 575px) {
    width: 100%;
  }
  .search-bar {
    :deep([type='search']) {
      border: 1px solid $gray-100;
    }
    flex: 1 1 auto;
    max-width: 300px;
  }
  .right-tool {
    display: flex;
    align-items: center;
    .mobile-mode-selector {
      user-select: none;
      margin-right: 8px;
    }
    .mobile-filter-toggle {
    }
  }
}
.is-small-screen {
  height: 100%;
  .location-left-block {
    max-width: 100%;
    overflow-y: auto;
    padding: 20px;
    .message-list-wrapper {
      overflow-y: visible;
      height: auto;
    }
    .message-list {
      overflow-y: visible;
      height: auto;
      .message-item {
        border-bottom: 1px solid #efefef;
      }
    }
  }
  .mobile-filter-bar {
    // position: absolute;
    // right: 0;
    width: calc(100% - 76px);
    // top: 52px;
    z-index: 1;
    display: flex;
    align-items: center;
    padding: 0 20px;
    @media (max-width: 575px) {
      width: 100%;
    }
    .custom-drp {
      border: 1px solid #ddd;
    }
    .ant-btn {
      border: 1px solid #ddd;
    }
  }
}

.search-mobile-wrapper {
  position: absolute;
  z-index: 1;
  width: 100%;
}

:deep(.ant-select-selector) {
  border: 0 !important;
}
</style>

<style lang="scss" scoped>
.location-drb {
  :deep(.ant-calendar-picker) {
    width: 100% !important;
  }
}
</style>
